<template>
    <div class="modal fade show" id="importUsersModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_importFromXLSFile") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="kt-section kt-section--first">
                        <h3 class="kt-section__title">{{ $t("common_importStepOneText") }}</h3>
                        <div role="alert" class="alert alert-secondary">
                            <div class="alert-icon">
                                <i class="flaticon-info kt-font-brand"></i>
                            </div>
                            <div class="alert-text">{{ $t("siteusers_importUsersHelpText") }}</div>
                        </div>
                        <div class="kt-section__body kt-align-center">
                            <a download href="/assets/xls-templates/template_import_users.xlsx" class="btn btn-success btn-sm btn-bold kt-margin-l-10" id="importUsers_downloadExcelTemplate">
                                <i class="fa fa-file-excel kt-margin-r-5"></i>
                                {{ $t("common_downloadXlsTemplate") }}
                            </a>
                        </div>

                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>

                        <h3 class="kt-section__title">{{ $t("common_importStepTwoText") }}</h3>

                        <div class="kt-section__body">
                            <div class="form-group kt-margin-b-0">
                                <label>{{ $t("common_fileBrowser") }}</label>
                                <div class="custom-file">
                                    <input type="file" @change="handleFileSelected" ref="xlsFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept=".xlsx" id="importUsersInputFile" />
                                    <label class="custom-file-label" id="importUsersInputLabel" for="customFile">{{ $t("common_chooseFile") }}</label>
                                    <span class="form-text text-muted">{{ $t("gtw_pleaseSelectXLSFile") }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="isXlsFileIsEmpty" class="kt-font-danger marginTMinus15">{{ $t("common_fileIsEmpty") }}</div>
                    <div v-if="isSheetNotFound" class="kt-font-danger marginTMinus15">{{ $t("siteusers_usersSheetNotFound") }}</div>

                    <div v-show="errorDisplayed" class="kt-section kt-margin-t-15 kt-margin-b-0">
                        <div class="kt-font-danger kt-margin-t-15 kt-margin-b-15">{{ $t("common_invalidXlsFile") }}</div>
                        <div class="kt-scroll" data-scroll="true" data-height="160">
                            <div class="kt-section__content">
                                <table class="table kt-font-danger table-sm">
                                    <thead>
                                        <tr>
                                            <th class="kt-align-center">{{ $t("common_rowNumber") }}</th>
                                            <th class="kt-align-center">{{ $t("error_message") }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(errorObj, i) in this.xlsErrors" :key="i">
                                            <td v-if="errorObj.row" class="kt-align-center font12" scope="row">{{ errorObj.row + 1 }}</td>
                                            <td v-else class="kt-align-center font12" scope="row">{{ $t("common_many") }}</td>

                                            <td v-if="errorObj.error == 'required'" class="kt-align-center font12">{{ $t("common_fieldIsRequired", { value: errorObj.column }) }}</td>
                                            <td v-else class="kt-align-center font12">{{ $t(errorObj.error, { value: errorObj.value }) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="importUsersModalImportButton" type="button" @click="importUsers" v-bind:disabled="isImportButtonDisabled || localIsUploadUsersInProgress" :class="{ 'btn btn-brand kt-margin-r-5 kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': localIsUploadUsersInProgress, 'btn btn-brand kt-margin-r-5': !localIsUploadUsersInProgress }">
                        {{ $t("common_import") }}
                    </button>
                    <button id="importUsersModalCancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">
                        {{ $t("common_cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import readXlsxFile from "read-excel-file";
import commonVueHelper from '../../helpers/commonVueHelper';

export default {
    data() {
        return {
            siteId: "",
            errorDisplayed: false,
            xlsData: null,
            isImportButtonDisabled: true,
            xlsErrors: null,
            xlsFile: null,
            isXlsFileIsEmpty: false,
            isSheetNotFound: false,
            mapSiteRolesByName: {},
            localIsUploadUsersInProgress: false
        };
    },
    created: function() {
        console.log("Component(ImportUsersModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(ImportUsersModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(ImportUsersModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        isUploadUsersInProgress: function() {
            this.localIsUploadUsersInProgress = this.isUploadUsersInProgress;
        },
        siteRolesByName: function() {
            console.log(`Component(ImportUsersModal)::watch(siteRoles) called`);
            this.mapSiteRolesByName = this.siteRolesByName;
        },
        showImportUsersModal() {
            if (this.showImportUsersModal) {
                console.log("Component(ImportUsersModal)::showImportUsersModal() - show modal");
                if (Object.keys(this.siteRolesByName).length === 0) {
                    this.getSiteRoles(this.siteId);
                }
                $("#importUsersModal").modal("show");
            }
            this.resetShowImportUsersModal();
        },
        hideImportUsersModal() {
            if (this.hideImportUsersModal) {
                console.log("Component(ImportUsersModal)::hideImportUsersModal() - hide modal");
                $("#importUsersModal").modal("hide");
            }
            this.resetHideImportUsersModal();
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["showImportUsersModal", "hideImportUsersModal", "siteRolesByName", "isUploadUsersInProgress"])
    },
    methods: {
        ...mapActions(["massImportUsers", "resetShowImportUsersModal", "resetHideImportUsersModal", "getSiteRoles", "sendInviteToUsers"]),
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        handleFileSelected() {
            console.log("Component(ImportUsersModal)::handleFileSelected() - called");

            this.errorDisplayed = false;
            this.isXlsFileIsEmpty = false;
            this.isSheetNotFound = false;

            if (this.$refs.xlsFile.files.length > 0) {
                this.xlsFile = this.$refs.xlsFile.files[0];

                const schema = {
                    Fullname: {
                        prop: "name",
                        type: String,
                        required: true,
                        parse(value) {
                            let valueName = value.toString();
                            if (valueName.length < 2) {
                                throw new Error("error_wrongNameLength");
                            }
                            return valueName;
                        }
                    },
                    "Email Address": {
                        prop: "email",
                        type: String, // ReferenceError: Email is not defined
                        required: true,
                        parse(value) {
                            if (!commonVueHelper.isValidEmail(value)) {
                                throw new Error("error_pleaseEnterValidEmail");
                            }
                            return value;
                        }
                    },
                    "Default Role Name": {
                        prop: "defaultRole",
                        type: String
                    },
                    "Custom Role Name": {
                        prop: "customRole",
                        type: String
                    }
                };

                var self = this;
                readXlsxFile(this.xlsFile, { schema, sheet: "users" })
                    .then(({ rows, errors }) => {
                        if (rows.length == 0 && errors.length == 0) {
                            this.isXlsFileIsEmpty = true;
                            return;
                        }

                        // Check manually if default or custom role Name is provided on each line
                        // and well refer to existing role of current site
                        for (let i = 0; i < rows.length; i++) {
                            let currentRow = rows[i];
                            if (currentRow.defaultRole && currentRow.customRole) {
                                errors.push({
                                    error: "error_duplicateRole",
                                    value: currentRow.email
                                });
                            } else if (!currentRow.defaultRole && !currentRow.customRole) {
                                errors.push({
                                    error: "error_missingRole",
                                    value: currentRow.email
                                });
                            } else if (currentRow.defaultRole && !currentRow.customRole) {
                                // Check if default role name well exist
                                if (!self.mapSiteRolesByName[currentRow.defaultRole]) {
                                    errors.push({
                                        error: "error_invalidDefaultRole",
                                        value: currentRow.email
                                    });
                                } else {
                                    currentRow.roleId = self.mapSiteRolesByName[currentRow.defaultRole].id;
                                    delete currentRow.defaultRole;
                                }
                            } else if (!currentRow.defaultRole && currentRow.customRole) {
                                // Check if default role name well exist
                                if (!self.mapSiteRolesByName[currentRow.customRole]) {
                                    errors.push({
                                        error: "error_invalidCustomRole",
                                        value: currentRow.email
                                    });
                                } else {
                                    currentRow.roleId = self.mapSiteRolesByName[currentRow.customRole].id;
                                    delete currentRow.customRole;
                                }
                            }
                        }

                        if (errors.length > 0) {
                            this.errorDisplayed = true;
                            this.isImportButtonDisabled = true;
                            this.xlsErrors = errors;
                        } else {
                            this.errorDisplayed = false;
                            this.isImportButtonDisabled = false;
                            this.xlsData = rows;
                        }
                    })
                    .catch(err => {
                        console.error(`Component(ImportUsersModal)::readXlsxFile - handle -`, err);
                        if (err.message.indexOf('Sheet "users" not found') != -1) {
                            this.isSheetNotFound = true;
                        }
                    });
            }
        },
        importUsers() {
            console.log("Component(ImportUsersModal)::importUsers() - called");
            const data = {
                from: "MASS_IMPORT",
                siteId: this.siteId,
                data: {
                    invitations: this.xlsData
                }
            };
            this.sendInviteToUsers(data);
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.cursorPointer {
    cursor: pointer;
}
.marginTMinus15 {
    margin-top: -15px;
}
</style>
<style>
.font12 {
    font-size: 12px;
}
</style>
